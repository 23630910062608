.signin-font {
    font-family:  sans-serif;
}

.logo-login {
    margin-bottom: 20px; 
    width: auto; 
    height: 100px;
}

.div-logo{
    text-align: center;
}

.hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    margin: 8px 0px;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 20px;
}

    .hr-sect:before,
    .hr-sect:after {
        content: "";
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.1);
        height: 1px;
        font-size: 0px;
        line-height: 0px;
        margin: 0px 8px;
    }