/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.Loader {
    height: 100vh;
    display: grid;
}

.Loader__background {
    display: none !important;
    z-index: 2000 !important;
    /* Full height */
    height: 100%;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

    .Loader__background.hide {
        display: none !important;
    }

    .Loader__background.show {
        display: block !important;
    }